import { Context } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import {
  Button,
  ButtonGroup,
  DisplayText,
  Link,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import Cookie from "js-cookie";
import * as React from "react";
import heroSvg from "./hero.svg";
import "./WelcomeHero.css";
import Emoji from "../Emoji";

const getTimeOfDay = () => {
  var today = new Date();
  var curHr = today.getHours();

  if (curHr < 12) {
    return "morning";
  } else if (curHr < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
};

export interface WelcomeHeroProps {}

const WelcomeHero: React.FC<WelcomeHeroProps> = () => {
  const app = React.useContext(Context);
  // @ts-ignore
  const redirect = Redirect.create(app);
  const name = Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-user-firstName`);
  return (
    <div
      className="welcome-hero___wrapper"
      style={{ backgroundImage: `url(${heroSvg})` }}
    >
      <TextContainer spacing="tight">
        <DisplayText size="large">
          Hey, {name || "You"}!
          <Emoji
            style={{ display: "inline", width: "25px", marginLeft: "10px" }}
            unicode="1f44b"
          />
        </DisplayText>
        <DisplayText size="small">
          <TextStyle variation="subdued">
            Have a very good {getTimeOfDay()}.
          </TextStyle>
        </DisplayText>
      </TextContainer>
      <div className="welcome-hero___buttons">
        <ButtonGroup>
          <Button
            onClick={() => {
              redirect.dispatch(Redirect.Action.APP, "/onboarding");
            }}
            primary
            size="large"
          >
            Get Started
          </Button>
          <div style={{ marginLeft: 10 }}>
            <Link url="https://help.codeword.tech/" external>
              View Documentation
            </Link>
          </div>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default WelcomeHero;
