import { Card, Heading, Icon, SkeletonBodyText, TextStyle, Button } from "@shopify/polaris";
import { CircleTickMajorMonotone } from "@shopify/polaris-icons";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import workIcon from "../cw-tools/frontend/Components/Dashboard/icons/work.png";

export default function SetupFirstAnnouncementBar() {
  const isDone = false;
  const history = useHistory();
  return (
    <Card.Section subdued={isDone}>
      <Link className="current-app-action-item___link" to="/customize" style={{ textDecoration: "none" }}>
        <div className="current-app-action-item___wrapper">
          <div className="current-app-action-item___content">
            <div>
              <img className="current-app-action-item___icon" src={workIcon} alt="Codeword App Icon" />
            </div>
            <div>
              <TextStyle variation={isDone ? "subdued" : "strong"}>
                <Heading>Customize Scroll Button</Heading>
              </TextStyle>
              <TextStyle variation="subdued">
                <p>Start with changing colors and placement of the button</p>
              </TextStyle>
            </div>
          </div>
          <Button
            onClick={() => {
              history.push("/customize");
            }}
          >
            Customize
          </Button>
        </div>
      </Link>
    </Card.Section>
  );
}
