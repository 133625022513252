import * as React from "react";
import { Layout } from "@shopify/polaris";
import { useMutation } from "react-apollo";
import { ApolloError, gql } from "apollo-boost";
import ConfigPanel from "./ConfigPanel/ConfigPanel";
import PreviewSection from "../../cw-tools/frontend/Components/PreviewSection/PreviewSection";
import ShopifySaveBar from "../../cw-tools/frontend/Components/ShopifySaveBar";
import { rgbToHex } from "../../cw-tools/frontend/helpers/rgbToHex";
import UpgradePlanModal from "../../cw-tools/frontend/Components/UpgradePlanModal";

export interface appSubscription {
  id: string;
  currentPeriodEnd: string;
  name: "PRO" | "FREE";
  status: "ACTIVE";
}

export interface CustomizerContainerProps {
  initialState: Record<string, any>;
  appSubscription: null | appSubscription;
}

const CONFIG_MUTATION = gql`
  mutation updateConfig($input: ConfigUpdateInput!) {
    updateOneConfig(input: $input) {
      id
      createdAt
      updatedAt
      merchant
      pluginEnabled
      position
      marginVertical
      marginHorizontal
      textSize
      borderRadius
      padding
      iconViewBox
      iconColor {
        r
        g
        b
        a
      }
      iconSize
      iconPaths
      iconId
      text
      scrollOffset
      inOutAnimation
      top
      left
      showOnName
      showOnRegexSource
      backgroundColor {
        r
        g
        b
        a
      }
    }
  }
`;

function CustomizerContainer(props: CustomizerContainerProps) {
  const [formState, setFormState] = React.useState(props.initialState);
  const [hasChanged, setHasChanged] = React.useState(false);
  const [showProModal, setShowProModal] = React.useState(false);
  const [updateConfig] = useMutation(CONFIG_MUTATION, {
    variables: {
      input: {
        ...formState,
        iconColor: rgbToHex(formState.iconColor),
        backgroundColor: rgbToHex(formState.backgroundColor),
      },
    },
    onError: handleError,
  });

  function handleChange(partialState: Partial<typeof formState>) {
    setFormState({
      ...formState,
      ...partialState,
    });
    setHasChanged(true);
  }

  function handleDiscard() {
    setFormState(props.initialState);
    setHasChanged(false);
  }

  function handleError(error: ApolloError) {
    if (error.message.includes("NOT_PRO")) {
      setShowProModal(true);
    }
    throw new Error(error.message);
  }

  function handleReset() {
    setFormState(props.initialState);
    setHasChanged(false);
    handleModalClose();
  }

  async function handleSave() {
    await updateConfig();
    setHasChanged(false);
  }

  function handleModalClose() {
    setShowProModal(false);
  }

  return (
    <ShopifySaveBar
      handleSave={handleSave}
      handleDiscard={handleDiscard}
      formState={{ hasChanged }}
    >
      <UpgradePlanModal
        active={showProModal}
        handleClose={handleModalClose}
        resetFunction={handleReset}
      />

      <Layout>
        <Layout.Section secondary>
          <ConfigPanel formState={formState} handleChange={handleChange} />
        </Layout.Section>
        <Layout.Section>
          <PreviewSection
            style={{ minHeight: 900, width: "100%" }}
            options={{
              developmentMode: true,
              targetId: "simple-scroll-to-top-codeword",
            }}
            formState={formState}
          />
        </Layout.Section>
      </Layout>
    </ShopifySaveBar>
  );
}

export default CustomizerContainer;
