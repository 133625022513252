import * as React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./cw-tools/frontend/Components/Dashboard/Dashboard";
import Login from "./cw-tools/frontend/Components/Login";
import Customize from "./pages/customize/customize";
import PricingPage from "./cw-tools/frontend/Pricing/PricingPage";

export interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  return (
    <Switch>
      <Route exact path="/" render={(props) => <Dashboard {...props} />} />
      <Route exact path="/login" render={(props) => <Login {...props} />} />
      <Route
        exact
        path="/customize"
        render={(props) => <Customize {...props} />}
      />
      <Route
        exact
        path="/onboarding"
        render={(props) => <Customize {...props} />}
      />
    </Switch>
  );
};

export default Routes;
