import * as React from 'react'
import { Form } from '@shopify/polaris'
import Positioning from './Positioning'
import Content from './Content'
import Styling from './Styling'
import Sizing from './Sizing'
import General from './General'
import Display from './Display'
import AdvancePositioning from './AdvancePositioning'

export interface ConfigPanelProps {
  formState: Record<string, any>
  handleChange: (partialState: Partial<ConfigPanelProps['formState']>) => void
}

export default class ConfigPanel extends React.Component<ConfigPanelProps> {
  render() {
    return (
      <Form onSubmit={() => {}}>
        <Positioning {...this.props} />
        <Content {...this.props} />
        <Styling {...this.props} />
        <Sizing {...this.props} />
        <AdvancePositioning {...this.props} />
        <General {...this.props} />
        <Display {...this.props} />
      </Form>
    )
  }
}
