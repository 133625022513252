import * as React from "react";
import SetupScrollToTopButton from "../SetupScrollToTopButton";

export interface GettingStartedProps {}

const GettingStarted: React.FC<GettingStartedProps> = () => {
  return (
    <div>
      <SetupScrollToTopButton />
    </div>
  );
};

export default GettingStarted;
