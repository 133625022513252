import * as React from "react";
import { Card, FormLayout, TextField } from "@shopify/polaris";
import IconPicker from "./IconPicker";
import { ConfigPanelProps } from "./ConfigPanel";

const Content = (props: ConfigPanelProps) => (
  <Card title="Content">
    <Card.Section>
      <FormLayout>
        <IconPicker {...props} />
        <TextField
          label="Button Text"
          value={props.formState.text}
          onChange={(value) => {
            props.handleChange({ text: value });
          }}
          clearButton
          onClearButtonClick={() => {
            props.handleChange({ text: "" });
          }}
        />
      </FormLayout>
    </Card.Section>
  </Card>
);

export default Content;
