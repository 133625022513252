import * as React from 'react'
import { Select, RangeSlider, Card, FormLayout } from '@shopify/polaris'
import { ConfigPanelProps } from './ConfigPanel'

const positionOptions = [
  { label: 'Top Left', value: 'TOP_LEFT' },
  { label: 'Top Right', value: 'TOP_RIGHT' },
  { label: 'Bottom Left', value: 'BOTTOM_LEFT' },
  { label: 'Bottom Right', value: 'BOTTOM_RIGHT' },
]

const Positioning = (props: ConfigPanelProps) => (
  <Card sectioned title="Positioning">
    <FormLayout>
      <Select
        label="Position"
        helpText="This value will be overridden if you use advance positioning"
        options={positionOptions}
        onChange={(value) => {
          props.handleChange({ position: value })
        }}
        value={props.formState.position}
      />
      <RangeSlider
        output
        label="Margin Vertical"
        min={0}
        max={40}
        value={props.formState.marginVertical}
        onChange={(value) => {
          props.handleChange({ marginVertical: value })
        }}
      />
      <RangeSlider
        output
        label="Margin Horizontal"
        min={0}
        max={40}
        value={props.formState.marginHorizontal}
        onChange={(value) => {
          props.handleChange({ marginHorizontal: value })
        }}
      />
    </FormLayout>
  </Card>
)

export default Positioning
