import * as React from "react";
import { Card, FormLayout, TextField, Select } from "@shopify/polaris";
import { ConfigPanelProps } from "./ConfigPanel";

const General = (props: ConfigPanelProps) => (
  <Card title="Miscellaneous">
    <Card.Section>
      <FormLayout>
        <TextField
          label="Scroll Offset"
          helpText="Show button after the user scrolls down (px)"
          type="number"
          min={0}
          value={props.formState.scrollOffset}
          onChange={(value) => {
            props.handleChange({ scrollOffset: parseInt(value) });
          }}
        />

        <Select
          label="Show and Hide Animation"
          value={props.formState.inOutAnimation}
          onChange={(value) => {
            props.handleChange({ inOutAnimation: value });
          }}
          options={[
            { label: "None", value: "NONE" },
            { label: "Fade", value: "FADE" },
            { label: "Fly", value: "FLY" },
            { label: "Slide", value: "SLIDE" },
          ]}
        />
      </FormLayout>
    </Card.Section>
  </Card>
);

export default General;
