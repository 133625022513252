import * as React from "react";
import { Card, FormLayout } from "@shopify/polaris";
import { ConfigPanelProps } from "./ConfigPanel";
import ColorPicker from "../../ColorPicker";

const Styling = (props: ConfigPanelProps) => (
  <Card title="Colors &amp; Styling">
    <Card.Section>
      <FormLayout>
        <ColorPicker
          label="Background Color"
          handleChange={(value) =>
            props.handleChange({ backgroundColor: value })
          }
          stateItem={"backgroundColor"}
          color={props.formState.backgroundColor}
        />
        <ColorPicker
          label="Icon Color"
          handleChange={(value) => props.handleChange({ iconColor: value })}
          stateItem={"iconColor"}
          color={props.formState.iconColor}
        />
      </FormLayout>
    </Card.Section>
  </Card>
);

export default Styling;
