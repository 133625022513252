import * as React from "react";
import { RangeSlider, Card, FormLayout } from "@shopify/polaris";
import { ConfigPanelProps } from "./ConfigPanel";

const Sizing = (props: ConfigPanelProps) => (
  <Card title="Sizing">
    <Card.Section>
      <FormLayout>
        <RangeSlider
          output
          label="Icon Size"
          min={10}
          max={60}
          value={props.formState.iconSize}
          onChange={(value) => {
            props.handleChange({ iconSize: value });
          }}
        />
        <RangeSlider
          output
          label="Text Size"
          min={10}
          max={60}
          value={props.formState.textSize}
          onChange={(value) => {
            props.handleChange({ textSize: value });
          }}
        />
        <RangeSlider
          output
          label="Padding"
          min={5}
          max={40}
          value={props.formState.padding}
          onChange={(value) => {
            props.handleChange({ padding: value });
          }}
        />
        <RangeSlider
          output
          label="Border Radius"
          min={0}
          max={40}
          value={props.formState.borderRadius}
          onChange={(value) => {
            props.handleChange({ borderRadius: value });
          }}
        />
      </FormLayout>
    </Card.Section>
  </Card>
);

export default Sizing;
