import * as React from "react";
import { ChoiceList, TextField, Card } from "@shopify/polaris";

export interface DisplayProps {
  formState: Record<string, any>;
  handleChange: (i: Record<string, any>) => void;
}

const Display: React.SFC<DisplayProps> = ({ formState, handleChange }) => {
  return (
    <Card title="Display">
      <Card.Section>
        <ChoiceList
          title="Which pages to display the button on"
          onChange={(values) => {
            const [value] = values;
            if (value === "homePage") {
              handleChange({ showOnName: value, showOnRegexSource: "" });
            } else if (value === "allPages") {
              handleChange({ showOnName: value, showOnRegexSource: "" });
            } else {
              handleChange({ showOnName: value, showOnRegexSource: "" });
            }
          }}
          choices={[
            { label: "All pages", value: "allPages" },
            { label: "Home page", value: "homePage" },
            {
              label: "Show if page's URL exactly matches this URL",
              value: "urlMatch",
            },
            {
              label: "Show if page's URL contains this string in URL",
              value: "stringMatch",
            },
          ]}
          selected={formState.showOnName}
        />

        {formState.showOnName !== "allPages" &&
          formState.showOnName !== "homePage" && (
            <TextField
              label={
                formState.showOnName === "urlMatch"
                  ? "Enter the url of page/product/collection"
                  : "Enter the text to match in url"
              }
              value={formState.showOnRegexSource}
              helpText={
                formState.showOnName === "urlMatch"
                  ? "Eg. If you want to show the app only on https://yourstore.com/collections/tshirts enter '/collections/tshirts'"
                  : "Eg. If you want to show the app on all collections https://yourstore.com/collections enter '/collections'"
              }
              onChange={(value) => {
                handleChange({
                  showOnRegexSource: value,
                });
              }}
            />
          )}
      </Card.Section>
    </Card>
  );
};

export default Display;
