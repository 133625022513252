import * as React from "react";
import { RangeSlider, Card, FormLayout, Button } from "@shopify/polaris";
import { ConfigPanelProps } from "./ConfigPanel";

function AdvancePositioning(props: ConfigPanelProps): JSX.Element {
  const { formState, handleChange } = props;

  return (
    <Card title="Advance Positioning">
      <Card.Section>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <span style={{ color: "#bf0711" }}>
            <Button
              plain
              monochrome
              onClick={() => {
                props.handleChange({
                  top: null,
                  left: null,
                });
              }}
            >
              Reset Advance Positioning
            </Button>
          </span>
        </div>
        <FormLayout>
          <RangeSlider
            label="Top"
            min={0}
            max={100}
            output
            //   use zero as an alternative to null, since null can't be
            //   represented in range slider
            value={formState.top || 0}
            onChange={(value) => {
              handleChange({ top: value });
            }}
          />
          <RangeSlider
            label="Left"
            min={0}
            max={100}
            output
            value={formState.left || 0}
            onChange={(value) => {
              handleChange({ left: value });
            }}
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
}

export default AdvancePositioning;
