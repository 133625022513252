import * as React from "react";
import { Button } from "@shopify/polaris";
import { ConfigPanelProps } from "./ConfigPanel";
import icons from "../../../icons";

const IconPicker = (props: ConfigPanelProps) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <span>Select Icon</span>
        <span style={{ color: "#bf0711" }}>
          <Button
            plain
            monochrome
            onClick={() => {
              if (props.formState.text === "") {
                props.handleChange({
                  iconPaths: [],
                  iconViewBox: "",
                  iconId: "",
                  text: "TOP",
                });
              } else {
                props.handleChange({
                  iconPaths: [],
                  iconViewBox: "",
                  iconId: "",
                });
              }
            }}
          >
            Remove Icon
          </Button>
        </span>
      </div>
      <div
        style={{ display: "flex", flexFlow: "row wrap", marginRight: "-10px" }}
      >
        {icons.map((icon) => (
          <div key={icon.id} style={{ marginRight: 10, marginBottom: 10 }}>
            <Button
              onClick={() => {
                props.handleChange({
                  iconPaths: icon.pathStrings,
                  iconViewBox: icon.viewBox,
                  iconId: icon.id,
                });
              }}
              outline={icon.id != props.formState.iconId}
              primary={icon.id === props.formState.iconId}
            >
              {/* @ts-ignore */}
              <svg width="24px" viewBox={icon.viewBox}>
                {icon.path}
              </svg>
            </Button>
          </div>
        ))}
      </div>
    </>
  );
};

export default IconPicker;
